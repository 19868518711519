@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}



@layer base {
  body {
    @apply font-primary text-primary;
  }
}

.sacramento-regular {
  font-family: "Sacramento", cursive;
  font-weight: 400;
  font-style: normal;
}

.dot__container {
  :global(.carousel .control-dots .dot) {
          background-color: blue;
        }
  }

@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap')






